import { signOut, useSession } from "next-auth/react";
import { Box, Container, Grid, Image, Link, Text } from "theme-ui";
import { useRouter } from "next/router";

const TopBarLegacy = () => {
  const { data } = useSession();
  const router = useRouter();

  const handleLogout = async () => {
    try {
      const logoutCognitoUrl = `https://${process.env.COGNITO_DOMAIN}/logout?client_id=${process.env.COGNITO_CLIENT_ID}&logout_uri=${process.env.DOMAIN}/login&redirect_uri=${process.env.DOMAIN}/login&response_type=code`;
      await signOut({
        redirect: false,
      });

      await router.push(logoutCognitoUrl);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container sx={{ position: "fixed", zIndex: 90, background: "white" }}>
      <Grid
        gap={4}
        columns={[4, "auto auto auto 1fr"]}
        sx={{
          alignItems: "center",
          padding: "12px 1rem 11px 1rem",
          borderBottom: "1px solid #D4D5D6",
          gridTemplateColumns: "1fr auto",
          "@media screen and (min-width: 40em)": {
            gridTemplateColumns: "auto 1fr max-content",
          },
          paddingLeft: "2rem",
        }}
      >
        <Box>
          <Link href="/" title="Retain Client Dashboard">
            <Image
              sx={{
                width: "98px",
                height: "40px",
              }}
              variant="logo"
              src="/static/retain-logo.svg"
              alt="Retain by TrueML Logo"
            />
          </Link>
        </Box>
        <Box
          sx={{
            "@media screen and (max-width: 40em)": {
              display: "none",
            },
          }}
        >
          <Text
            sx={{
              color: "charcoal70",
              fontSize: "20px",
              fontWeight: "600",
              whiteSpace: "nowrap",
            }}
          >
            Client Dashboard
          </Text>
        </Box>
        {data && (
          <Box style={{ textAlign: "right" }}>
            <Text
              variant="strong"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Image variant="avatar" src="/static/user.svg" />
              <Text
                variant="tiny"
                color="charcoal50"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline-block",
                  verticalAlign: "-5px",
                }}
              >
                {data?.user?.name}
              </Text>
            </Text>
            <Text
              variant="tiny"
              color="charcoal50"
              p={2}
              onClick={handleLogout}
            >
              |
            </Text>
            <Text
              variant="strong"
              sx={{
                cursor: "pointer",
                textDecorationColor: "primary",
                textDecorationStyle: "solid",
                textDecorationThickness: "1px",
                textDecorationLine: "underline",
                textUnderlineOffset: "2px",
              }}
            >
              <Text variant="tiny" color="primary" onClick={handleLogout}>
                Sign Out
              </Text>
            </Text>
          </Box>
        )}
      </Grid>
    </Container>
  );
};

export default TopBarLegacy;
